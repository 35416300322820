<template>
  <div id="Tienda">
    <Header :white="true" />
    <div class="tienda-body">
      <div class="sec-1 header">
        <div class="producto-cont" v-for="(pro, i) in productos" :key="i">
          <div class="flexin" v-if="pro.seccion == 'Encabezado'">
            <img :src="pro.image" alt="producto" class="producto-image" />
            <a href="" class="producto-category">
              {{ pro.categoría }}
            </a>
            <p class="producto-artista">
              Artista
              <a href=""
                ><b>{{ pro.artista }}</b></a
              >
            </p>
            <a href="">
              <p class="producto-titulo">
                {{ pro.titulo }}
              </p>
            </a>

            <p class="producto-price">${{ pro.precio }}MXN</p>
          </div>
        </div>
        <img src="../assets/tienda-1.png" alt="header" class="header-image" />
      </div>
      <div class="sec-2">
        <div class="flexin first-item">
          <img
            :src="this.productosPop[1].image"
            alt="producto"
            class="producto-image"
          />
          <a href="" class="producto-category">
            {{ this.productosPop[1].categoría }}
          </a>
          <p class="producto-artista">
            Artista
            <a href=""
              ><b>{{ this.productosPop[1].artista }}</b></a
            >
          </p>
          <a href="">
            <p class="producto-titulo">
              {{ this.productosPop[1].titulo }}
            </p>
          </a>

          <p class="producto-price">${{ this.productosPop[1].precio }}MXN</p>
        </div>
        <div class="col-s2-2">
          <div
            v-for="(pro, i) in fistElement(productosPop)"
            :class="{ 'mas-populares': pro.seccion == 'Mas populares' }"
            :key="i"
          >
            <div class="flexin" v-if="pro.seccion == 'Mas populares'">
              <img :src="pro.image" alt="producto" class="producto-image" />
              <a href="" class="producto-category">
                {{ pro.categoría }}
              </a>
              <p class="producto-artista">
                Artista
                <a href=""
                  ><b>{{ pro.artista }}</b></a
                >
              </p>
              <a href="">
                <p class="producto-titulo">
                  {{ pro.titulo }}
                </p>
              </a>

              <p class="producto-price">${{ pro.precio }}MXN</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../components/on-site/Header";
import Footer from "../components/on-site/Footer";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      productos: [
        {
          image: require("../assets/tienda-1.png"),
          categoría: "Hombre",
          artista: "Aciid",
          titulo:
            "Extensión de formulario para clientes potenciales en Google Ads: Búsqueda, Display, Youtube y Discovery",
          precio: 450,
          seccion: "Encabezado",
        },
        {
          image: require("../assets/tienda-2.png"),
          categoría: "Hombre",
          artista: "Aciid",
          titulo:
            "Extensión de formulario para clientes potenciales en Google Ads: Búsqueda, Display, Youtube y Discovery",
          precio: 450,
          seccion: "Encabezado",
        },
      ],
      productosPop: [
        {
          image: require("../assets/tienda-2.png"),
          categoría: "Hombre",
          artista: "Aciid",
          titulo:
            "Extensión de formulario para clientes potenciales en Google Ads: Búsqueda, Display, Youtube y Discovery",
          precio: 450,
          seccion: "Mas populares",
        },
        {
          image: require("../assets/tienda-2.png"),
          categoría: "Hombre",
          artista: "Aciid",
          titulo:
            "Extensión de formulario para clientes potenciales en Google Ads: Búsqueda, Display, Youtube y Discovery",
          precio: 450,
          seccion: "Mas populares",
        },
        {
          image: require("../assets/tienda-1.png"),
          categoría: "Hombre",
          artista: "Aciid",
          titulo:
            "Extensión de formulario para clientes potenciales en Google Ads: Búsqueda, Display, Youtube y Discovery",
          precio: 450,
          seccion: "Mas populares",
        },
        {
          image: require("../assets/tienda-1.png"),
          categoría: "Hombre",
          artista: "Aciid",
          titulo:
            "Extensión de formulario para clientes potenciales en Google Ads: Búsqueda, Display, Youtube y Discovery",
          precio: 450,
          seccion: "Mas populares",
        },
      ],
    };
  },
  methods: {
    fistElement(array) {
       array.shift();
      return array;
    },
  },
};
</script>
<style scoped>
.sec-1.header {
  display: flex;
  width: fit-content;
  /*justify-content: space-between;*/
  margin: auto;
  align-items: flex-end;
  padding-top: 3.646vw;
}
.producto-cont {
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: auto;
}
.sec-1 .producto-cont:nth-child(1) {
  order: 1;
}
.sec-1 .producto-cont:nth-child(2) {
  order: 3;
}
.producto-image {
  width: 22.396vw;
  height: 25.573vw;
  object-fit: cover;
  margin-bottom: 1.719vw;
}
.sec-1 .header-image {
  width: 41.042vw;
  height: auto;
  order: 2;
  margin: 0 3.073vw;
}
a.producto-category {
  font-family: "Gramatika-Bold";
  font-size: 0.417vw;
  width: 3.281vw;
  height: 1.198vw;
  background: #b2acfc;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
p.producto-artista {
  font-family: "Gramatika-Medium";
  margin: 0.99vw 0 0;
  font-weight: 400;
  font-size: 0.521vw;
  line-height: 125%;
  color: #bdbdbd;
}
.producto-titulo {
  width: 20.208vw;
  font-family: "Gramatika-Medium";
  font-weight: 400;
  font-size: 1.042vw;
  line-height: 125%;
  color: #000000;
  margin: 0.99vw 0 0;
}
p.producto-artista a {
  color: #515151;
}
p.producto-price {
  font-family: "Gramatika-Bold";
  font-weight: 400;
  font-size: 1.042vw;
  line-height: 125%;
  color: #ef67c2;
  margin: 1vw 0 0;
}

.sec-2 {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  height: 70vw;
  padding-top: 3.906vw;
}
.first-item .producto-image {
  width: 39.583vw;
  height: 55.417vw;
}
.first-item .producto-titulo {
  width: 31.979vw;
  font-family: "Gramatika-Medium";
  font-weight: 400;
  font-size: 1.042vw;
  line-height: 125%;
  color: #000000;
  margin: 0.99vw 0 0;
}
.mas-populares:nth-of-type(1) .producto-image {
  width: 38.75vw;
  height: 25.573vw;
}
.mas-populares {
  height: fit-content;
  width: fit-content;
}
</style>